@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

*{
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box !important;
  font-family: 'Lato', sans-serif;
}


@media(max-width: 992px ){
  .mx-sm-auto{
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media(max-width:500px){
  *{
    overflow-x: hidden;
  }
}

.load{
  position: absolute;
  vertical-align: middle !important;
  margin-top: 50vh;
}

.navbar{
  background-color: #fefefe !important;
}


.navbar-toggler:focus{
  box-shadow: none !important;
}

@media(max-width: 992px){

  

	.navbar{
    margin-right: 20px !important;
    margin-left: 20px !important;

  }
  .navbar a{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
}



.navbar-brand{
  font-size: 1.9rem !important;
  color: #565387 !important;
}



/* Brand */



.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 80px;
}


/* Brand */



.navbar a{
  font-size: 0.8rem;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
  font-weight:650;
  letter-spacing: 1px;
  color: #504f4f !important;
}



.listItem{
  font-size: 0.7rem !important;
}

/* .dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
} */

/* map */
.mapouter{position:relative;text-align:right;width:100%;height:300px;}
.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:300px;}
.gmap_iframe {height:300px!important;}


.card-img-top{
  max-height: auto;
  width: 55% !important;
}

.Drake{
  height: 85% !important;
  width: 75% !important;
}

.navbar a:hover{
  color: #3366cc !important;
}

.navbar a:focus{
  border: none !important;
  box-shadow: none !important;
}
.navbar a:active{
  border: none !important;
  box-shadow: none !important;
}

.animated{
  width: 100%;
  height: 90vh !important;
  object-fit: cover;
  
}

.about_image{
  min-width: 80%;
  max-height: 280px;
  object-fit: cover;
}

.about_image2{
  min-width: 78%;
  max-height: 320px;
  object-fit: cover;
}

.form-check .form-check-input {
  float: left;
  margin-left: 0 !important;
  margin-right: 1rem ;
}

.about_imageGallery{
  min-width: 100%;
  max-height: 200px;
  object-fit: cover;
}

@media screen and (min-width:374px) and (max-width:992px){
  .about_imageGallery{
    max-width: 250px !important;
    max-height: 180px !important;
    object-fit: cover;
  }
  }

.about_imageGalleryCertificate{
  min-width: 60%;
  max-height: 60vh;
  object-fit: cover;
}

.main_heading{
width: 100% !important;
}

.bg-overlay {
  background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(./images/twoer.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  height: 600px;
  padding-top: 50px;

}

.innerHome{
  justify-content: center;
  align-items: center;
}

.HomeWords{
  letter-spacing: 3px;
  font-size: 2.3em !important;
  color: #504e4c;
  font-weight: 700 !important;
}

.smallerDesc{
  font-size: 0.7rem !important;
  font-weight: 500 !important;
}

.aboutContent{
  font-size: 75% !important;
  font-family: 'Source Sans Pro', sans-serif;
  color: #212529 !important;
}

/* ul li::marker{
 margin-left: 10px !important;
} */

.bruh li {
  margin-left: 20px !important;
  padding-left: 10px !important;
}

@media screen and (min-width:374px) and (max-width:992px){
  .bruh li {
    margin-left: 0px !important;
    padding-left: 0px !important;
  } 
}


body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*  */

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
  color: #bbb5af;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
  color: #bbb5af;
}

input::placeholder, textarea::placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::placeholder, textarea::focus:placeholder {
  color: #bbb5af;
}

input::-ms-placeholder, textarea::-ms-placeholder {
  color: #aca49c;
  font-size: 0.875em;
}

input:focus::-ms-placeholder, textarea:focus::-ms-placeholder {
  color: #bbb5af;
}

/* on hover placeholder */

input:hover::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder {
  color: #f7f7f7;
  font-size: 0.875em;
}

input:hover:focus::-webkit-input-placeholder, textarea:hover:focus::-webkit-input-placeholder {
  color: #ffffff;
}

input:hover::-moz-placeholder, textarea:hover::-moz-placeholder {
  color: #ffffff;
  font-size: 0.875em;
}

input:hover:focus::-moz-placeholder, textarea:hover:focus::-moz-placeholder {
  color: #ffffff;
}

input:hover::placeholder, textarea:hover::placeholder {
  color: #ffffff;
  font-size: 0.875em;
}

input:hover:focus::placeholder, textarea:hover:focus::placeholder {
  color: #ffffff;
}

input:hover::placeholder, textarea:hover::placeholder {
  color: #ffffff;
  font-size: 0.875em;
}

input:hover:focus::-ms-placeholder, textarea:hover::focus:-ms-placeholder {
  color: #ffffff;
}

body {
  font-family: 'Lato', sans-serif;
  background: #e2dedb;
  color: #b3aca7;
}

header {
  position: relative;
  margin: 100px 0 25px 0;
  font-size: 2.3em;
  text-align: center;
  letter-spacing: 7px;
}

#form {
  position: relative;
  width: 31.25rem;
  margin: 3.125rem auto 6.25rem auto;
}

.form-control{
  width: 30% !important;
}

input {
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  height: 50px;
  
  background: transparent;
  outline: none;
  color: #726659;
  
  border: solid 1px #b3aca7;
  border-bottom: none;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

@media screen and (min-width:375px) and (max-width:992px){
.form-control{
  width: 70% !important;
}
}

input:hover {
  background: #ffffff;
  color: #000000;
}

textarea {
  height: 110px;
  max-height: 110px;
  
  background: transparent;
  outline: none;
  
  color: #000000;
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  
  border: solid 1px #b3aca7;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

textarea:hover {
  background: #ffffff;
  color: #e2dedb;
}

#submit {
  
  margin: -5px 0px 0px 0px;
  
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  color: #b3aca7;
  
  outline:none;
  cursor: pointer;
  
  border: solid 1px #b3aca7;
  border-top: none;
}

#submit:hover {
  color: #e2dedb;
}

/*  */
/* Footer Copy */
.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}

.ahr{
  box-shadow: 0 2px 7px rgb(0 0 0 / 0.2) !important;
}

.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373;
  text-decoration: none;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0 !important;
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center !important;
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
/*  */
/* image full screen */
.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  transition: opacity ease 0.4s;
}

.show {
  visibility: visible;
  opacity: 1;
  border: none !important;
}

.show_image {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
/*  */

/* TABLE */

.selected {
  background-color: #c0c1c25b !important; 
}
.table{
	width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #2a253a;
  box-shadow: 0 2px 7px rgb(0 0 0 / 0.2);


  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;

}

.table tr{
  vertical-align: middle;
}

.table td,.table th{
  padding:0.75rem 0.9375rem;
  text-align: center;
  font-size:1rem;
  color: #525364;
  border: 1px solid rgb(240, 239, 238);
}

.table th{
	color:rgb(232, 232, 232);
  font-weight: 700;
  padding: 0.1875rem  !important;
  vertical-align: middle;
  background-color: #161616 !important;
  
}



.table tbody tr:nth-child(even){
	background-color: #ffffff;
}
tbody tr:nth-child(odd) {
  background-color: #ffffff;
}


/*responsive*/

@media screen and (min-width:1024px) {
  table thead {
    position: sticky;
    top: 0;
  }
  
  table tbody {
    overflow-y: scroll;
    max-height: 200px; /* Set the maximum height of the scrollable area */
  }
}

@media(max-width: 1023px){


  

  .table tbody tr td:nth-child(1){
    display: none;
  }


	.table thead{
		display: none;
	}

	.table, .table tbody, .table tr, .table td{
		display: block;
		width: 100%;
	}
	.table tr{
		margin-bottom:50px;
	}
	.table td{
		text-align: right;
		position: relative;
    padding-right: 25px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
	}
	.table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 60% !important;
		padding-left:15px !important;
		font-size:13px;
		font-weight: bold;
		text-align: left;
	}
}
